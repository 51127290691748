import './App.css';
import NavBar from "./components/NavBar";
import HeroSection from "./components/HeroSection";
import DisplaySection from "./components/DisplaySection";
import Footer from "./components/Footer";

function App() {
  return (
      <body>
      < NavBar />
      < HeroSection />
      < DisplaySection />
      < Footer />
      </body>
  );
}

export default App;
